.headshotsDiv {
    width: 160px;
    display: inline-block;
}

.headshotPhoto {
    width: 150px;
    margin-top: 56px;
}

.headshots {
    text-align: center;
}

.headshotsButton {
    margin-bottom: 20px;
}

.hCalendly {
    margin-top: 66px;
    padding-bottom: 10px;
    height: 700px;
    background-color: #dad6ac;
}

.gCalendly {
    margin-top: 66px;
    padding-bottom: 30px;
    height: 700px;
    background-color: #dad6ac;
}

.photos {
    margin-bottom: -50px;
}

@media only screen and (max-width: 1300px) {
    #h1 {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    #h2 {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .hCalendly {
        margin-top: 66px;
        padding-bottom: 36px;
        height: 700px;
        background-color: #dad6ac;
    }

    .gCalendly {
        margin-top: 66px;
        padding-bottom: 0px;
        height: 700px;
        background-color: #dad6ac;
    }

    #h1 {
        display: none;
    }

    .headshotsDiv {
        width: 120px;
        display: inline-block;
    }

    .headshotPhoto {
        width: 112.5px;
        margin-top: 56px;
    }
}

.greenColor {
    background-color: #dad6ac;
}

.specify {
    margin-left: 50px;
    margin-right: 50px;
    padding-bottom: 33px;
    margin-block-end: 0em;
}