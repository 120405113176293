.categories {
  text-align: center;
  padding-top: 100px;
  margin-bottom: 15px;
}

.headshotCoverPhoto {
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.bestFriendsCoverPhoto {
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.gradCoverPhoto {
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.headshotButton {
}

.bestFriendsButton {
}

.gradButton {
}

.headshotDiv {
  width: 340px;
  display: inline-block;
}

.bestFriendsDiv {
  width: 340px;
  display: inline-block;
}

.gradDiv {
  width: 340px;
  display: inline-block;
}

@media only screen and (max-width: 639px) {
  .categories {
    text-align: center;
    padding-top: 15px;
  }
}

.availabilityCal {
  margin-top: 50px;
  margin-bottom: 15px;
  border-width: 0px;
}

.calendar {
  margin-top: 100px;
  background-color: #dad6ac;
  text-align: center;
  margin-bottom: -15px;
}

.calText {
  margin-right: 200px;
  margin-left: 200px;
  text-align: center;
  vertical-align: middle;
  padding-bottom: 50px;
}

@media only screen and (min-width: 1000px) {
  .calText {
    margin-right: 250px;
    margin-left: 250px;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 50px;
  }

  .gradDiv {
    width: 300px;
    background-size: contain;
    display: inline-block;
    position: relative;
  }

  .headshotDiv {
    width: 300px;
    background-size: contain;
    display: inline-block;
    position: relative;
  }
  
  .bestFriendsDiv {
    width: 300px;
    display: inline-block;
    position: relative;
    margin-left: 40px;
    margin-right: 40px;
    background-size: contain;
  }

  .gradCoverPhoto {
    width: 300px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .headshotCoverPhoto {
    width: 300px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .bestFriendsCoverPhoto {
    width: 300px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: #dad6ac;
  }
  
  .gradDiv:hover .overlay {
    opacity: 0.5;
  }

  .headshotDiv:hover .overlay {
    opacity: 0.5;
  }

  .bestFriendsDiv:hover .overlay {
    opacity: 0.5;
  }

  .text {
    color: black;
    font-size: 48px;
    padding-top: 180px;
    font-weight: 500;
  }

}

@media only screen and (min-width: 999px) {
  .mobilebutton {
    display: none;
  }
}

@media only screen and (max-width: 999px) {

  .overlay {
    display: none;
  }

  .calText {
    margin-right: 50px;
    margin-left: 50px;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 50px;
  }

  .availabilityCal {
    width: 400px;
    margin-top: 50px;
    margin-bottom: 15px;
    border-width: 0px;
  }

}

@media only screen and (max-width: 600px) {
  .calendar {
    margin-top: 25px;
    background-color: #dad6ac;
    text-align: center;
    margin-bottom: -15px;
  }

  .gradDiv {
    width: 120px;
    padding-top: 200px;
    display: inline-block;
}

  .gradCoverPhoto {
      width: 112.5px;
      margin-top: 0px;
  }

  .bestFriendsDiv {
    width: 120px;
    display: inline-block;
  }

  .bestFriendsCoverPhoto {
      width: 112.5px;
      margin-top: 0px;
  }

  .headshotDiv {
    width: 120px;
    display: inline-block;
  }

  .headshotCoverPhoto {
      width: 112.5px;
      margin-top: 0px;
  }
}

@viewport {
  width: device-width ;
  zoom: 1.0 ;
}
